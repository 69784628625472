<template>
    <section>
        <!-- Message modal -->
        <b-modal :active.sync="ageVerificationModalActive" has-modal-card trap-focus aria-role="dialog" :can-cancel="false" aria-modal scroll="keep">
            <div class="modal-card" style="width: auto">
                <header class="modal-card-head">
                    <p class="modal-card-title has-text-centered has-text-weight-bold">Annual Policy age limit exceeded</p>
                </header>
                <section class="modal-card-body">
                    <p>
                        The details entered fall outside Annual Policy parameters. Annual policy holders can only be insured up to the age of 79. However, we would be happy to offer you a Single Trip policy for your travel needs. We apologise for any inconvenience.  
                    </p>
                </section>
                <footer class="modal-card-foot level">
                    <button class="button level-item" type="button" @click="onClick">Close</button>
                </footer>
            </div>
        </b-modal>
    </section>
</template>

<script>
    export default {
        name: "AgeVerificationModal",
        props: ['ageVerificationModalActive'],
        methods: {
            onClick() {
                this.ageVerificationModalActive = false;
                this.$emit('modalActive', false);
            }
        }
    }
</script>

<style scoped>
    .error {
        color: red;
    }
</style>