<template>
    <base-summary-container>

        <transition-group enter-active-class="animated fadeInDown faster" leave-active-class="animated fadeOutUp faster">
            <div class="summary-section" v-if="quoteData.policyType" key="s1">
                <strong>POLICY TYPE</strong>: {{ $c2g.policyType[quoteData.policyType].toUpperCase() }}
            </div>
            <div class="summary-section" v-if="quoteData.travelZone" key="s2">
                <strong>TRAVEL AREA</strong>: {{ $c2g.area[quoteData.travelZone].toUpperCase() }}
            </div>
            <div class="summary-section" v-if="quoteData.dates.length > 1" key="s3">
                <strong>DATES</strong>: {{ startDate }} to {{ endDate }}
            </div>
            <div class="summary-section" v-if="quoteData.duration > 0 && quoteData.dates.length > 1 && quoteData.policyType === 'ST'" key="s7">
                <strong>DURATION</strong>: {{ quoteData.duration }} DAYS
            </div>
            <div class="summary-section" v-if="quoteData.relationship" key="s4">
                <strong>RELATIONSHIP</strong>: {{ $c2g.relationship[quoteData.relationship].toUpperCase() }}
            </div>
            <div class="summary-section" v-if="quoteData.ages.length > 0" key="s5">
                <strong>AGES</strong>: {{ quoteData.ages.join(", ") }}
            </div>
            <div class="summary-section" key="s10">
                <b-icon icon="check-circle" size="is-small" type="is-primary"/> Level {{ quoteData.activityPack }}
            </div>
            <div class="summary-section" key="s8" v-show="quoteData.tripCost > 0">
                <strong>TRIP COST</strong>: &pound;{{ $helper.thousandSeparator(quoteData.tripCost) }}
            </div>

            <div class="summary-section" v-if="Object.entries(quoteData.endorsements).length !== 0" key="s6">
                <strong>ENDORSEMENTS</strong>
                <transition-group enter-active-class="animated fadeInDown faster" leave-active-class="animated fadeOutUp faster">
                    <div v-for="(v, k) in quoteData.endorsements" :key="k">
                        <b-icon icon="check-circle" size="is-small" type="is-primary"/> {{ endorsements[k] }}
                    </div>
                </transition-group>
            </div>

        </transition-group>

        <div class="summary-marketing">
            <div>
                <h2>Why Choose Us?</h2>
                <ul>
                    <li>
                        <img src="/img/award-winning-travel-insurance.png" />
                        <p>Award-Winning Travel Insurance</p>
                    </li>
                    <li>
                        <img src="/img/24-hour-emergency-assistance.png" />
                        <p>24-Hour Emergency Medical Assistance</p>
                    </li>
                    <li>
                        <img src="/img/safe-and-secure-online-booking.png" />
                        <p>Safe & Secure Online Booking</p>
                    </li>
                    <li>
                        <img src="/img/instant-policy-delivery.png" />
                        <p>Instant Online Policy Delivery</p>
                    </li>
                    <li>
                        <img src="/img/14-day-cooling-off-period.png" />
                        <p>14 Day Cooling Off Period</p>
                    </li>
                </ul>
            </div>
            <div class="defaqto-item">
                <img src="/img/defaqto.png" alt="Defaqto expert rating logo">
            </div>
            <trustpilot-widget />
        </div>

    </base-summary-container>
</template>

<script>
    import BaseSummaryContainer from "../../common/BaseSummaryContainer";
    import TrustpilotWidget from '../../common/trustpilot/TrustpilotWidget';

    export default {
        name: "QuoteSummary",
        data() {
            return {
                endorsements: {}
            }
        },
        props: {
            quoteData: { type: Object, requied: true }
        },
        computed: {
            // Format start date to UK style
            startDate() {
                return this.$moment(this.quoteData.dates[0]).format('DD/MM/YYYY');
            },
            // Format end date to UK style
            endDate() {
                return this.$moment(this.quoteData.dates[1]).format('DD/MM/YYYY');
            }
        },
        components: {
            BaseSummaryContainer,
            TrustpilotWidget
        },
        created() {
            this.$c2g.getEndorsements('AT').then((response) => {
                Object.entries(response.data).forEach((v) => {
                    this.$set(this.endorsements, v[0], v[1][0]);
                });
            });
        }
    }
</script>

<style lang="scss" scoped>
    .summary-section {
        padding: 3px 0 3px 0;
        border-bottom: 3px solid $c2g_orange;
    }
    .covidimg {
        margin-top: 10px;
        border-radius: 5px;
    }
    
    .summary-marketing {
        margin: 5px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;

        h2 {
            font-weight: 800;
            text-align: center;
            margin: 10px;
        }

        ul {            
            margin: 5px;
        }

        li {
            display: flex;
            align-content: flex-start;
            align-items: center;
            font-size: 0.9em;

            p {
                margin: 0 0 5px 5px;
            }
        }

        .defaqto-item {
            margin-top: 5px;
        }

        .tp-item {
            margin-top: 5px;
        }
    }
</style>