<template>
    <div v-if="!this.affiliation">
        <h5 class="title is-5">Promotional Code</h5>

        <!-- Error messsage -->
        <article class="message is-danger" v-if="invalid">
            <div class="message-body">
                Invalid or expired promocode entered
            </div>
        </article>

        <div class="columns is-multiline" v-if="this.policy.policy.promo_code === '' && this.policy.policy.status === 'quote'">
            <div class="column">
                <input type="text" class="input" placeholder="Enter Promotional Code" v-model="code">
            </div>
            <div class="column">
                <button class="button is-info is-fullwidth" :class="{'is-loading': loading}" @click.prevent="applyCode">Add Promotional Code</button>
            </div>
        </div>
        <div class="success" v-else>
            Promotional code has been applied to this policy
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";
    import { policyAPI } from "../../../mixins/policy-api";

    export default {
        name: "PromoCodeInput",
        data() {
            return {
                code: '',
                invalid: false,
                loading: false
            }
        },
        methods: {
            ...mapActions({
                setPolicy: 'setPolicy',
            }),
            applyCode() {
                this.loading = true;
                this.applyPromoCode(this.policy.quote.id, this.code).then(res => {
                    if(res.data.policy.promo_code !== "") {
                        this.setPolicy(res.data);
                        this.invalid = false;
                    } else {
                        this.invalid = true;
                    }
                    this.loading = false;
                    location.reload();
                });
            }
        },
        computed: {
            ...mapGetters({
                policy: 'policy',
                affiliation: 'affiliation'
            })
        },
        mixins: [ policyAPI ]
    }
</script>

<style lang="scss" scoped>
    h5.title {
        margin-top: 20px;
        margin-bottom: 10px;
    }
    input {
        margin-bottom: 20px;
    }
    .success {
        padding-top: 0;
        padding-bottom: 20px;
    }
    input {
        margin: 0;
    }
    .column {
        padding-bottom: 0;
    }
</style>