<template>
    <div>
        <div class="title is-5 level">
            Medical Screening Reference
            <b-button :type="'is-light'" size="is-small" @click="showInfo = !showInfo" class="hvr-icon-bob">Need Help <i class="fas fa-question-circle hvr-icon"/></b-button>
        </div>

        <transition appear enter-active-class="animated fadeIn faster" leave-active-class="animated fadeOut faster">
            <div v-if="showInfo" class="box">
                <p>
                    For full travel insurance cover with medical conditions, you must purchase a policy from
                    covered2go.co.uk, as well as, declaring your conditions to our Medical Screening Service, the two
                    policies will run concurrently. Make a note of the Covered2go product that most suits your needs and
                    give the Medical Screening Service a call on +44 (0)330 311 2648. If you are unsure whether you need
                    to disclose your conditions, please click here for further information.
                </p>
                <p>
                    Please leave the box blank if you do not need to declare medical conditions. If you have already
                    declared your conditions and have a reference number, please enter it here for our records. If you
                    have not declared your medical conditions yet, you can let us know of your medical screening
                    reference later, email us and we will update your records.
                </p>
            </div>
        </transition>

        <div class="columns">
            <div class="column">
                <input type="text" class="input" placeholder="Enter Medical Screening Number" v-model="policy.policy.medical_screening_num">
            </div>
            <div class="column">
                <button class="button is-info is-fullwidth" :class="{'is-loading': loading}" @click.prevent="apply">Add / Update Medical Screening Number</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";
    import { policyAPI } from "@/mixins/policy-api";

    export default {
        name: "MedicalScreeningInput",
        data() {
            return {
                loading: false,
                showInfo: false
            }
        },
        mixins: [ policyAPI ],
        methods: {
            ...mapActions({
                setPolicy: 'setPolicy',
            }),
            apply() {
                this.loading = true;
                this.savePolicy(this.policy).then((res) => {
                    this.setPolicy(res.data);
                    this.loading = false;
                });
            }
        },
        computed: {
            ...mapGetters({
                policy: 'policy'
            })
        }
    }
</script>

<style lang="scss" scoped>
    h5.title {
        margin-top: 20px;
        margin-bottom: 10px;
    }
    input {
        margin-bottom: 0px;
    }
    .box {
        p {
            margin-bottom: 8px;
            margin-top: 8px;
        }
    }
</style>