<template>
    <div :class="{'error': quoteData.errors.relationship}">

        <!-- Error text -->
        <span id="error-relationship" :v-if="quoteData.errors.relationship" class="input-error">{{ quoteData.errors.relationship }}</span>

        <div class="columns">
            <div class="input-label title is-6 column">
                <div>Who Is Going?</div>
                <b-button style="float: right; color: #494949;" :type="'is-ghost'" size="is-small" @click="showInfo = !showInfo" class="hvr-icon-bob is-hidden-desktop"><i class="fas fa-question-circle hvr-icon"/></b-button>
            </div>

            <div class="column is-two-fifths">
                <b-select placeholder="Select your policy type" expanded v-model="quoteData.relationship">
                    <option v-for="(v, k) in relationshipTypes" :key="k" :value="k" >{{ v }}</option>
                </b-select>
            </div>

            <div class="column is-hidden-touch">
                <b-button style="float: right; color: #494949;" :type="'is-ghost'" size="is-small" @click="showInfo = !showInfo" class="hvr-icon-bob"><i class="fas fa-question-circle hvr-icon"/></b-button>
            </div>
        </div>

        <transition appear enter-active-class="animated fadeIn faster" leave-active-class="animated fadeOut faster">
            <div v-if="showInfo" class="box">
                <p><strong>Individual</strong> Each person shown on the policy schedule, for whom the appropriate insurance premium has been paid.</p>
                <br>
                <p><strong>Couple</strong> Two adults who have been permanently living together at the same address for more than six months. Each adult can travel independently.</p>
                <br>
                <p><strong>Group</strong> Up to fourteen adult travellers.</p>
                <br>
                <p><strong>Family</strong> Two adults and all of their children (including foster children) aged under 18 (or under age 23 if in full time education). All persons must live at the same address. Each adult can travel independently, however, all insured children, irrespective of age, must travel with at least one of the insured adults.</p>
                <br>
                <p><strong>Single parent family</strong> One adult and all of their children (including foster children) aged under 18 (or under age 23 if in full time education). All persons must live at the same address. Each adult can travel independently, however, all insured children, irrespective of age, must travel with at least one of the insured adults.</p>
            </div>
        </transition>
        <!-- <box-radio-selector :col-size="'is-3'" :options="relationshipTypes" v-model="quoteData.relationship"/> -->

    </div>
</template>

<script>
    //import BoxRadioSelector from "../../common/form/BoxRadioSelector";

    export default {
        name: "QuoteRelationshipSelector",
        data() {
            return {
                showInfo: false
            }
        },
        props: ['quoteData'],
        computed: {
            relationshipTypes() {
                // Remove group relationship in AT
                const types = Object.assign({}, this.$c2g.relationship);
                if(this.quoteData.policyType === 'AT' || this.quoteData.policyType === 'CR-AT' ) {
                    delete types['group'];
                }

                delete types['extfamily'];

                return types;
            }
        },
        watch: {
            'quoteData.policyType'() {
                this.quoteData.relationship = '';
            },
            'quoteData.relationship'() {
                this.quoteData.errors.relationship = '';
            }
        },
        components: {
            //BoxRadioSelector
        }
    }
</script>

<style scoped>
    .input-label {
        margin-top: 10px;
        margin-bottom: 0;
        padding-bottom: 0;
    }
    .error {
        border: 2px solid red;
        border-radius: 5px;
        padding: 5px 10px 20px 10px;
    }
</style>