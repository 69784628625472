<template>
    <section>
        <div class="columns">
            <div class="input-label title is-6 column">
                <div>Select Your Policy Type</div>
                <b-button style="float: right; color: #494949;" :type="'is-ghost'" size="is-small" @click="showInfo = !showInfo" class="hvr-icon-bob is-hidden-desktop"><i class="fas fa-question-circle hvr-icon"/></b-button>
            </div>

            <!--<box-radio-selector :options="$c2g.policyType" v-model="quoteData.policyType"/>-->

            <div class="column is-two-fifths">
                <b-select expanded v-model="quoteData.policyType">
                    <option v-for="(v, k) in $c2g.policyType" :key="k" :value="k" :selected="k === 'ST'" >{{ v }}</option>
                </b-select>
            </div>

            <div class="column is-hidden-touch">
                <b-button style="float: right; color: #494949;" :type="'is-ghost'" size="is-small" @click="showInfo = !showInfo" class="hvr-icon-bob"><i class="fas fa-question-circle hvr-icon"/></b-button>
            </div>

        </div>
        <transition appear enter-active-class="animated fadeIn faster" leave-active-class="animated fadeOut faster">
            <div v-if="showInfo" class="box">
                <strong>Single Trip</strong> travel insurance will cover you for one particular trip. The cover ends when you return home at the end of your trip, and no further trips are covered.<br>Maximum Age Limit: 99<br><br>
                <strong>Annual Multi Trip</strong> travel insurance may be more cost effective for you if you're planning more than one or two trips in the next twelve months. A Multi Trip policy will cover you for an unlimited number of trips in a 12-month period, as long as each trip is no more than 31 days (unless you purchase the 45 or 60 day extension option). Please note, if a family or group policy is purchased, only those over 18 will be covered to travel independently of the named adults.<br>Maximum Age Limit: 79<br><br>
                <strong>Longstay</strong> travel insurance is specifically designed to cover those longer adventures abroad. Our policies can provide cost effective cover for up to 18 months for those aged between 18 and 64.<br><br>
                <strong>Cruise Cover Information</strong> No cover is provided for cruise holidays unless you have selected the ‘Cruise Cover’ option, which you can select under the ‘Additional Cover Options’ below. Cruises are defined as a trip on an ocean, sea or river going vessel which is used for both accommodation and transportation. A ferry crossing does not constitute a cruise.
            </div>
        </transition>
    </section>
</template>

<script>
    //import BoxRadioSelector from "../../common/form/BoxRadioSelector";

    export default {
        name: "PolicyTypeSelector",
        data() {
            return {
                showInfo: false
            }
        },
        props: ['quoteData'],
        components: {
           // BoxRadioSelector
        },
    }
</script>

<style scoped>
    .input-label {
        margin-top: 10px;
        margin-bottom: 0;
        padding-bottom: 0;
    }
</style>