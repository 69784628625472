<template>
    <div>
        <div class="input-label title is-6">
            <div>Email Address?</div>
        </div>
        <p id="email-info">
            We will email you a summary of your quote details for reference so you can access your quote in the future.
            If you purchase a policy today we'll send you a confirmation email containing your policy details.
        </p>
        <span :v-if="quoteData.errors.email !== ''" class="input-error">{{ quoteData.errors.email }}</span>
        <b-input placeholder="Email Address" type="email" icon="email" v-model="quoteData.email"/>
    </div>
</template>

<script>
    export default {
        name: "QuoteEmail",
        props: ['quoteData'],
    }
</script>

<style scoped>
    .input-label {
        margin-top: 0;
        margin-bottom: 5px;
    }
    #email-info {
        margin-bottom: 7px;
    }
</style>