<template>
    <div>
        <quote-breadcrumbs/>
        <div id="container" class="columns">
            <!-- <covid-popup :covidPopup='covidPopup'/> -->
            <transition appear enter-active-class="animated fadeInUp" leave-active-class="animated fadeOut">
                <quote-form v-if="active" :quote-data="quoteData" :quote-fn="quote" :content="content" :covidPopup='covidPopup'/>
            </transition>
            <transition appear enter-active-class="animated fadeInUp" leave-active-class="animated fadeOut">
                <quote-summary v-if="active" :quote-data="quoteData"/>
            </transition>
        </div>
        <student-cover :open="studentPopupActive" @read="studentCover" :relationship="quoteData.relationship"/>
    </div>
</template>

<script>
    import QuoteBreadcrumbs from "@/components/quote-journey/QuoteBreadcrumbs";
    import QuoteSummary from "@/components/quote-journey/get-quote/QuoteSummary";
    import QuoteForm from "@/components/quote-journey/get-quote/QuoteForm";
    import StudentCover from "@/components/quote-journey/get-quote/StudentCoverModal";
    // import CovidPopup from "@/components/common/CovidPopup";
    import { mapGetters, mapActions } from 'vuex';
    import { policyAPI } from "@/mixins/policy-api";
    import { contentAPI } from "@/mixins/content-api";
    import { page } from "@/mixins/page";
    import { maintenance } from "@/mixins/maintenance";

    export default {
        name: "GetQuote",
        data() {
            return {
                covidPopup: { active: true },
                studentPopupActive: false,
                studentPopupRead: false,
                active: true,
                quoteData: {
                    policyType: 'ST',
                    travelZone: null,
                    // set the time to midnight to make the system consider today as a full day
                    dates: [new Date(new Date().setHours(0,0,0,0))],
                    duration: 0,
                    relationship: '',
                    paxAmount: 0,
                    ages: [],
                    dobs: [],
                    passengers: [],
                    endorsements: {},
                    activities: '',
                    activityPack: 1,
                    tripCost: 0,
                    medScreeningNumber: '',
                    email: '',
                    formLoading: false,
                    marketing: false,
                    promocode: '',
                    affilliation: '',
                    errors: {
                        travelZone: '',
                        startDate: '',
                        endDate: '',
                        relationship: '',
                        passengers: [],
                        email: ''
                    }
                }
            }
        },
        methods: {
            ...mapActions({
                setPolicy: 'setPolicy',
                setReqPolicy: 'setReqPolicy',
                setUpsellPolicy: 'setUpsellPolicy'
            }),

            studentCover(value) {
                this.studentPopupRead = value;
                this.studentPopupActive = false;

                if (this.studentPopupRead) {
                    this.quote();
                }
            },

            validate() {
                if(this.quoteData.errors.endDate !== '') {
                    this.$scrollTo('#error-travel-zone');
                    return false; 
                }

                // Clear errors
                for (const [key] of Object.entries(this.quoteData.errors)) {
                    this.quoteData.errors[key] = '';
                }
                this.quoteData.errors.passengers = [];

                // Validate inputs
                if(!this.quoteData.travelZone) {
                    this.$set(this.quoteData.errors, 'travelZone', 'Please enter your travel area');
                    this.$scrollTo('#error-travel-zone');
                    return false;

                } else if(!this.quoteData.dates[0] || !this.quoteData.dates[1]) {
                    this.quoteData.errors.startDate = 'Please enter your start date';
                    this.quoteData.errors.endDate = 'Please enter your end date';
                    this.$scrollTo('#error-travel-zone');
                    return false;

                } else if(!this.quoteData.relationship) {
                    this.quoteData.errors.relationship = 'Please enter your relationship type';
                    this.$scrollTo('#error-relationship');
                    return false;

                } else {
                    for(let i = 0; i < this.quoteData.paxAmount; i++) {
                        if(!this.quoteData.dobs[i]) {
                            this.quoteData.errors.passengers[i] = 'Please enter this date of birth';
                            this.$scrollTo('#pax-error-'+i);
                            return false;
                        }

                        let dob = this.$moment(this.quoteData.dobs[i], 'DD/MM/YYYY');
                        const ageWorkout = this.quoteData.policyType.includes('AT') ? this.$moment(this.quoteData.dates[0]).diff(dob, 'years') : this.$moment().diff(dob, 'years');
                        if(ageWorkout < 0 || ageWorkout > 99) {
                            this.quoteData.errors.passengers[i] = 'Invalid date of birth';
                            this.$scrollTo('#pax-error-'+i);
                            return false;
                        } else if (this.quoteData.relationship === 'group' && ageWorkout < 18) {
                            this.quoteData.errors.passengers[i] = 'Please enter a valid DOB. Anyone named on a Group Policy must be aged 18 and over. Please contact us if you need any further assistance.';
                            this.$scrollTo('#pax-error-'+i);
                            return false;
                        }
                    }
                }
                if(!this.quoteData.email) {
                    this.quoteData.errors.email = 'Please enter your email';
                    return false;
                }

                if (typeof this.quoteData.cruise !== "undefined" && this.quoteData.cruise === "Yes") {

                    if (this.quoteData.tripCost > this.paxAmount * 10000) {
                        this.quoteData.errors.tripCost = "The maximum trip cost per person is £10,000. Please contact us if you need any further assistance.";
                        this.$scrollTo('#tripCost-error');
                        return false;
                    }

                }

                if (this.quoteData.relationship === "family" || this.quoteData.relationship === "oneparent") {

                    let adults = 0;
                    let students = 0;

                    for (let i = 0; i < this.quoteData.paxAmount; i++) {
                        if (this.quoteData.dobs[i]) {

                            let dob = this.$moment(this.quoteData.dobs[i], 'DD/MM/YYYY');
                            const ageWorkout = this.quoteData.policyType.includes('AT') ? this.$moment(this.quoteData.dates[0]).diff(dob, 'years') : this.$moment().diff(dob, 'years');

                            if (ageWorkout > 18 && ageWorkout <= 22) {
                                students++;
                            } else if (ageWorkout >= 23) {
                                adults++;
                            }
                        }
                    }

                    if (students > 0 && !this.studentPopupRead) {
                        this.studentPopupActive = true;
                        return false;
                    } else if (adults > 2) {
                        this.quoteData.errors.relationship = 'Only 2 adults allowed on a family policy';
                        this.$scrollTo('#error-relationship');
                        return false;
                    }

                }


                return true;
            },

            // Set the policy object
            setPolicyObj(res) {
                this.setPolicy(res.data);
                this.setReqPolicy(res.data);
            },

            // Uses the current quote form contents to generate a quote
            quote() {
                if(this.validate()) {

                    // Set loading
                    this.quoteData.formLoading = true;
                    this.$scrollTo('html');

                    let quoteid = null;

                    if (this.quoteData.policyType.includes('CR-')) {
                        this.quoteData.policyType = this.quoteData.policyType.replace('CR-', '');
                        this.quoteData.endorsements['cruise'] = 'Yes';
                    }
                    this.quoteData.affilliation = this.affiliation;
                    // Get a quote
                    this.getQuote()
                        .then((res) => {
                            this.setPolicyObj(res);
                            return this.setPassengers(res.data.quote.id, this.quoteData.passengers);
                        })
                        .then(res => {
                            // Set extra policy data
                            res.data.quote.email_address = this.quoteData.email;
                            res.data.details.email = this.quoteData.email;
                            res.data.policy.medical_screening_num = this.quoteData.medScreeningNumber;
                            res.data.endorsements.activities = this.quoteData.activities;
                            res.data.endorsements.activity_pack = this.quoteData.activityPack;
                            res.data.quote.trip_cost = this.quoteData.tripCost;
                            if(this.callCentre !== null) {
                                res.data.policy.call_centre = 'Y';
                                res.data.policy.profile = this.callCentre;
                            }

                            if (this.affiliation !== null) {
                                res.data.policy.affilliation = this.affiliation;
                                res.data.quote.affilliation = this.affiliation;
                            }
                            this.setPolicyObj(res);

                            // Save extra data
                            return this.savePolicy(this.reqPolicy);
                        })
                        .then(res => {
                            this.setPolicyObj(res);
                            quoteid = res.data.quote.id;
                            return this.setEndorsements(quoteid, this.quoteData.endorsements);
                        })
                        .then(res => {
                            this.setPolicyObj(res);

                            // Add marketing
                            // const marketing = this.quoteData.marketing ? quoteid : 0;
                            // return this.addMarketing(marketing, this.quoteData.email);

                            // RR-181 Send email and marketing info to new endpoint
                            return this.updateMarketing(quoteid, this.quoteData.email, this.quoteData.marketing);
                        })
                        .then(() => {
                            return this.applyPromoCode(quoteid, this.quoteData.promocode);
                        })
                        .then((res) => {

                            this.setPolicyObj(res);

                            // Create up-sell if policy is ST
                            if(this.quoteData.policyType === 'AT' || this.quoteData.policyType === 'LS') {
                                this.active = false;
                                // Redirect
                                this.$router.push({name: 'policyDetails'});
                            } else {
                                // Create an up-sell policy
                                this.createUpsellPolicy(this.reqPolicy.quote.id)
                                    .then(res => {
                                        // Retrieve the up-sell policy
                                        if(res.data.policy) {
                                            this.setPolicyObj(res);
                                            return this.retrievePolicy(this.reqPolicy.quote.upsellid);
                                        } else {
                                            this.$router.push({name: 'policyDetails'});
                                            return Promise.reject('Upsell out of bounds');
                                        }
                                    })
                                    .then(res => {
                                        this.setUpsellPolicy(res.data);
                                        // Set passengers of the up-sell policy
                                        return this.setPassengers(this.upsellPolicy.quote.id, this.quoteData.passengers);
                                    })
                                    .then(() => {
                                        return this.applyPromoCode(this.reqPolicy.quote.upsellid, this.quoteData.promocode);
                                    })
                                    .then((res) => {
                                        this.setUpsellPolicy(res.data);
                                        if(this.reqPolicy.quote) {
                                            // Redirect
                                            this.$router.push({name: 'policyDetails'});
                                        }
                                    })
                                    .catch(err => {
                                        this.quoteData.formLoading = false;
                                        this.active = false;
                                        console.log(err);
                                    });
                            }
                        })
                        .catch(err => {
                            this.quoteData.formLoading = false;
                            this.active = true;
                            console.log(err);
                        })
                }
            },


        },
        // Pre populate data with previous quote
        mounted() {
            if(this.policy) {
                this.quoteData.policyType = this.policy.policy.policy_type;
                this.quoteData.travelZone = this.policy.policy.policy_area;
                this.$set(this.quoteData.dates, 0, new Date(this.policy.policy.start_date));
                this.$set(this.quoteData.dates, 1, new Date(this.policy.policy.end_date));
                this.quoteData.relationship = this.policy.policy.relationship;
                this.quoteData.paxAmount = this.policy.policy.total_pax;
                this.policy.passengers.forEach((px, i) => {
                    this.$set(this.quoteData.dobs, i, this.$moment(px.dob, 'YYYY-MM-DD').format('DD/MM/YYYY'));
                });
                this.quoteData.email = this.policy.quote.email_address;
                this.quoteData.tripCost = this.policy.quote.trip_cost;
                this.quoteData.medScreeningNumber = this.policy.policy.medical_screening_num;
                this.quoteData.marketing = (this.policy.policy.mkt_opt_in == 'Y' ? true : false);
                this.quoteData.affilliation = this.policy.policy.affilliation;
            } else {
                this.quoteData.policyType = this.$route.query.type;
                if (this.customer) {
                    this.quoteData.email = this.customer.email;
                }
            }
        },
        computed: {
            ...mapGetters({
                policy: 'policy',
                reqPolicy: 'reqPolicy',
                upsellPolicy: 'upsellPolicy',
                callCentre: 'callCentre',
                affiliation: 'affiliation',
                customer: 'customer',
            })
        },
        mixins: [ policyAPI, contentAPI, page, maintenance ],
        components: {
            QuoteBreadcrumbs,
            QuoteForm,
            QuoteSummary,
            StudentCover,
            // CovidPopup
        },
        async created() {
            await this.maintenanceCheck();
        }
    }
</script>

<style lang="scss" scoped>
    #container {
        align-items: flex-start;
    }
</style>