<template>
    <div>
        <b-field label="Address Search" label-position="on-border">
            <b-input v-model="searchQuery" placeholder="Search your address.." :loading="loadingSearch" type="search" icon="magnify"/>
        </b-field>
        <div class="address-search-results" v-show="items.length > 0">
            <div class="address-row" v-for="i in items" :key="i.id">
                <button class="button is-small is-info" @click.prevent="selectItem(i.Id, i.Type)">{{ i.Text + ', ' + i.Description }}</button>
            </div>
        </div>

        <b-field label="Postcode" label-position="on-border">
            <input type="text" class="input" name="postcode" v-model="policy.details.postcode" required>
        </b-field>
        <b-field label="Address1" label-position="on-border">
            <input type="text" class="input" name="address1" v-model="policy.details.address1" required>
        </b-field>
        <b-field label="Address2" label-position="on-border">
            <input type="text" class="input" name="address2" v-model="policy.details.address2">
        </b-field>
        <b-field label="Address3" label-position="on-border">
            <input type="text" class="input" name="address3" v-model="policy.details.address3">
        </b-field>
        <b-field label="Town" label-position="on-border">
            <input type="text" class="input" name="town" v-model="policy.details.town" required>
        </b-field>
        <b-field label="County" label-position="on-border">
            <input type="text" class="input" name="county" v-model="policy.details.county" required>
        </b-field>
        <b-field label="Country" label-position="on-border">
            <input type="text" class="input" name="county" v-model="policy.details.country" required>
        </b-field>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import axios from "axios";

    export default {
        name: "AddressSelector",
        data() {
            return {
                items: [],
                searchQuery: '',
                container: '',
                loadingSearch: false
            }
        },
        computed: {
            ...mapGetters({
                policy: 'policy'
            })
        },
        watch: {
            searchQuery(v) {
                if(v !== '') {
                    this.container = '';
                    this.searchAddress();
                }
            }
        },
        methods: {
            selectItem(id, type) {
                this.searchQuery = '';
                if(type === 'Address') {
                    this.items = [];
                    this.container = '';
                    this.selectAddress(id);
                } else {
                    this.container = id;
                    this.searchAddress();
                }
            },
            searchAddress() {
                this.loadingSearch = true;
                // Make the initial search call
                axios.create({
                    baseURL: `https://services.postcodeanywhere.co.uk/Capture/Interactive/Find/v1.10/json3.ws?Key=pf44-yg17-jw56-re47&Text=${this.searchQuery}&Countries=GBR&IsMiddleware=true&Container=${this.container}&limit=100`
                }).get('').then((res) => {
                    this.items = res.data.Items;
                    this.loadingSearch = false;
                    // If there is one result (Non-address), then find individual addresses
                    if(this.items.length === 1 && this.container === '' && this.items[0].Type !== 'Address') {
                        this.container = this.items[0].Id;
                        this.searchAddress();
                    }
                }).catch((err) => {
                    console.log(err);
                });
            },
            selectAddress(id) {
                this.loadingSearch = true;
                axios.create({
                    baseURL: `https://services.postcodeanywhere.co.uk/Capture/Interactive/Retrieve/v1.00/json3.ws?Key=pf44-yg17-jw56-re47&Id=${id}`
                }).get('').then((res) => {
                    const item = res.data.Items[0];
                    this.policy.details.postcode = item.PostalCode;
                    this.policy.details.address1 = item.Line1;
                    this.policy.details.address2 = item.Line2;
                    this.policy.details.address3 = item.Line3;
                    this.policy.details.town = item.City;
                    this.policy.details.county = item.Province;
                    this.policy.details.country = item.CountryName;
                    this.loadingSearch = false;
                }).catch((err) => {
                    console.log(err);
                });
            },
            mounted() {
                if(this.policy.details.postcode !== '') {
                    this.searchAddress();
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .address-search-results {
        border: 1px solid gray;
        border-radius: 5px;
        margin: 20px 0;
        max-height: 200px;
        overflow-y: scroll;
    }
    .address-row {
        margin: 5px;
    }
</style>