<template>
    <b-modal :active.sync="open" has-modal-card trap-focus aria-role="dialog" aria-modal :can-cancel="false" scroll="keep">
        <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
                <p class="modal-card-title has-text-centered has-text-weight-bold">Student Cover</p>
            </header>
            <section class="modal-card-body">
                <p v-if="relationship == 'family'">
                    The details entered fall outside the family policy parameters. A family must be 2 adults and all of their
                    children (including foster children) aged under 18 (or under age 23 if in full time education). All persons
                    must live at the same address. Each adult can travel independently, however, all insured children,
                    irrespective of age, must travel with at least one of the insured adults.
                </p>
              <p v-if="relationship == 'oneparent'">
                The details entered fall outside the Single Parent Family policy parameters.
                A Single Parent Family must be 1 adult and all of their children (including foster children) aged under 18 (or under age 23 if in full time education).
                All persons must live at the same address. The adult can travel independently, however, all insured children, irrespective of age, must travel with the insured adult.
              </p>
            </section>
            <footer class="modal-card-foot level">
                <button class="button level-item" type="button" @click="cancelModal">Cancel</button>
                <button class="button is-primary level-item" @click="readModal">Okay</button>
            </footer>
        </div>
    </b-modal>
</template>

<script>
    export default {
        name: "StudentCoverModal",
        props: ['open', 'relationship'],
        methods: {
            cancelModal() {
                this.$emit('read', false);
            },
            readModal() {
                this.$emit('read', true);
            }
        }
    }
</script>

<style scoped>

</style>