<template>
    <div>
        <!-- Initial covers -->
        <div class="columns" v-for="cover in initialCovers" :key="cover['cover_limit_id']">
            <div class="column cover-title">
                <b-tooltip v-if="cover['coverdesc'] !== ''" position="is-right" size="is-large" multilined :style="{cursor: 'pointer'}">
                    <span class="has-text-left" v-html="cover['covername']"></span>
                    <template v-slot:content>
                        <div class="has-text-centered" v-html="`<strong>${cover['coverdesc']}</strong>`"></div>
                    </template>
                </b-tooltip>
                <span v-else class="has-text-left" v-html="cover['covername']"></span>
            </div>
            <div class="column product-container" v-for="product in productsOffered" :key="product.id">
                <span class="mobile-product-elements" v-html='$helper.stringCapFirst(productCoverName(product)) + " "'></span>
                
                <template v-if="cover['covername'] === 'Main Excess'">
                    <b-tooltip v-if="cover['coverdesc'] !== ''" class="has-tooltip-multiline" position="is-right" size="is-large" multilined :style="{cursor: 'pointer'}">
                        <div class="cost-display has-text-left" v-html='coverCostDisplay(cover[`${productCoverName(product)}_limit`])'></div>
                        <template v-slot:content>
                            <div class="has-text-centered" v-html="`<strong>${cover['coverdesc']}</strong>`"></div>
                        </template>
                    </b-tooltip>
                    <span v-else class="has-text-left" v-html="cover['covername']"></span>
                </template>

                <template v-else>
                    <div class="cost-display" v-html='coverCostDisplay(cover[`${productCoverName(product)}_limit`])'></div>
                </template>
            </div>
        </div>

        <!-- Other covers -->
        <template v-if="hiddenCovers">

            <!-- Endorsement specific covers -->
            <div class="columns" v-for="cover in extraCovers" :key="cover['cover_limit_id']">
                <div class="column cover-title">
                    <b-tooltip v-if="cover['coverdesc'] !== ''" position="is-right" size="is-large" multilined :style="{cursor: 'pointer'}">
                        <span class="has-text-left" v-html="cover['covername']"></span>
                        <template v-slot:content>
                            <div class="has-text-centered" v-html="`<strong>${cover['coverdesc']}</strong>`"></div>
                        </template>
                    </b-tooltip>
                    <span v-else class="has-text-left" v-html="cover['covername']"></span>
                </div>
                <div class="column product-container" v-for="product in productsOffered" :key="product.id" v-html='coverCostDisplay(cover[`${productCoverName(product)}_limit`])'></div>
            </div>

            <div class="columns" v-for="cover in otherCovers" :key="cover['cover_limit_id']">
                <div class="column cover-title">
                    <b-tooltip v-if="cover['coverdesc'] !== ''" position="is-right" size="is-large" multilined :style="{cursor: 'pointer'}">
                        <span class="has-text-left" v-html="cover['covername']"></span>
                        <template v-slot:content>
                            <div class="has-text-centered" v-html="`<strong>${cover['coverdesc']}</strong>`"></div>
                        </template>
                    </b-tooltip>
                    <span v-else class="has-text-left" v-html="cover['covername']"></span>
                </div>
                <div class="column product-container" v-for="product in productsOffered" :key="product.id">
                    <span class="mobile-product-elements" v-html='$helper.stringCapFirst(productCoverName(product)) + " "'></span>
                    <div class="cost-display" v-html='coverCostDisplay(cover[`${productCoverName(product)}_limit`])'></div>
                </div>

            </div>

        </template>

        <div id="show-button-container" class="level-item">
            <button class="button is-fullwidth b-shdw-1" :class="[hideCoversAnimType]" @click="hiddenCovers = !hiddenCovers">
                {{ !hiddenCovers ? 'Show other cover levels' : 'Hide cover levels' }}
                <i :class="['fa', 'hvr-icon', hideCoversIcon]"/>
            </button>
        </div>

        <button v-show="hiddenCovers" class="button is-info is-fullwidth b-shdw-1" v-scroll-to="'html'"><i class="fas fa-arrow-circle-up"/></button>

        <div class="covid-message">
            Please Remember - There is no cover on any of our policies if you travel against the FCDO (Foreign, Commonwealth and Development Office), government, local authority, or medical advice.
            Terms and Conditions Apply. Please see our <a href="https://covered2go.co.uk/policy-wordings-mul" target="_blank">Policy Wording</a> for full details.
        </div>
        <div class="covid-message">
            * Please note - If you require cancellation cover above £10,000 per person, we can 'top it up' for you. You can purchase up to £20,000 extra cancellation cover per person (£15,000 per person if aged 75-79), with an overall limit of £100,000 per policy. Please <a href="https://www.covered2go.co.uk/contact-us" target="_blank">Contact Us</a> directly for a quote.
        </div>
        <div class="covid-message">
            ** If you're living in The Channel Islands we'll remove the IPT cost before you make your policy payment.
        </div>
    </div>
</template>

<script>
    export default {
        name: "ProductCovers",
        data() {
            return {
                initialCovers: [],
                secondCovers: [],
                otherCovers: [],
                extraCovers: [],
                hiddenCovers: false
            }
        },
        props: {
            productsOffered: { required: true },
            productCoverName: {
                type: Function,
                required: true
            },
            cruise: { type: String },
            ws: { type: String }
        },
        methods: {
            // Cover cost display format
            coverCostDisplay(v) {
                let index;
                let colour = "red";

                if (v.includes('Not covered')) {
                    index = v.indexOf('Not covered');
                } else if (v.includes('Not included')) {
                    index = v.indexOf('Not included');
                } else if (v.includes('Included')) {
                    index = v.indexOf('Included');
                    colour = 'green';
                } else if (v.includes('Optional')) {
                    index = v.indexOf('Optional');
                    colour = 'black';
                }

                if (index >= 0) {
                    return `${v.substring(0, index)}<strong style="color: ${colour};">${v.substring(index, index + v.length)}</strong>`;
                } else {
                    return v;
                }
            },
        },
        computed: {
            // Hide / show icon
            hideCoversIcon() {
                return this.hiddenCovers ? 'fa-angle-double-up' : 'fa-angle-double-down';
            },
            hideCoversAnimType() {
                return this.hiddenCovers ? 'hvr-icon-bob' : 'hvr-icon-hang';
            }
        },
        created() {
          const isLongStay = this.productCoverName(this.productsOffered[0]) === 'longstay';
          // Retrieve cover limits from API
            this.$c2g.getCoverLimits("WHERE cruise = 0 AND ski = 0").then((res) => {
                const data = JSON.parse(res.data);

                const retrievedCovers = data.filter((c) => {
                    if (isLongStay) {
                        return c['longstay_limit'] !== 'N/A'
                    } else {
                        return true;
                    }
                });

                // Order the first few covers specifically - YF note, this is not the best way to do this, we should add a sort order in the database, this is a dirty solution naughty Rob!!
               const coverOrder = ['61', '1', '2', '3', '12', '13', '14', '15'];
                 coverOrder.forEach((c) => {
                    retrievedCovers.forEach((r) => {
                        if (r['cover_limit_id'] === c) {
                            this.initialCovers.push(r);
                        }
                    })
                 });

                // Add the rest of the covers
                retrievedCovers.forEach((c) => {
                    if (!coverOrder.includes(c['cover_limit_id'])) {
                        if (isLongStay && c['longstay_limit'] !== '') {
                            this.otherCovers.push(c);
                        } else if (!isLongStay) {
                          this.otherCovers.push(c);
                        }
                    }
                });
            });

            // Winter sports
            if(this.ws === 'Yes') {
                this.$c2g.getCoverLimits("WHERE ski = 1").then((res) => {
                    JSON.parse(res.data).forEach((c) => {
                        this.extraCovers.push(c);
                    });
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "src/styles/views/product-selection";

    .covid-message {
        background-color: #f9923167;
        margin: 10px;
        padding: 10px;
        border-radius: 10px;
    }
</style>