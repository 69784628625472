<template>
    <div>
        <quote-breadcrumbs :step="2"/>
        <div id="container" class="columns" v-if="policy.quote.product && policy.policy.sell_price">
            <transition appear enter-active-class="animated fadeInUp" leave-active-class="animated fadeOut">
                <details-form v-if="active" :save-fn="save"/>
            </transition>
            <transition appear enter-active-class="animated fadeInUp" leave-active-class="animated fadeOut">
                <quote-summary v-if="active"/>
            </transition>
        </div>
        <div class="box text-center" v-else>
            Product needs to be selected on the previous page.
        </div>
    </div>
</template>

<script>
    import QuoteBreadcrumbs from "../components/quote-journey/QuoteBreadcrumbs";
    import QuoteSummary from "../components/quote-journey/your-details/QuoteSummary";
    import DetailsForm from "../components/quote-journey/your-details/DetailsForm";
    import { policyUsefulFunctions } from "../mixins/policy-useful-functions";
    import { policyAPI } from "../mixins/policy-api";
    import { mapActions, mapGetters } from 'vuex';
    import { maintenance } from "../mixins/maintenance";

    export default {
        name: "YourDetails",
        data() {
            return {
                active: true
            }
        },
        computed: {
            ...mapGetters({
                policy: 'policy'
            })
        },
        methods: {
            ...mapActions({
                setPolicy: 'setPolicy',
            }),
            save() {
                this.savePolicy(this.policy)
                    .then((res) => {
                        if(res.data.quote) {
                            this.setPolicy(res.data);
                            // return this.addMarketing(this.policy.policy.policynum);

                            // RR-181 Send email with marketing info
                            return this.updateMarketing(this.policy.policy.policynum, this.policy.details.email, (this.policy.policy.mkt_opt_in == 'Y' ? true : false));
                        }
                    })
                    .then(() => {
                        this.active = false;
                        this.$scrollTo('html');
                        // Redirect
                        setTimeout(() => {
                            this.$router.push({name: 'purchasePolicy'});
                        }, 1000);
                    });
            }
        },
        mixins: [
            policyUsefulFunctions,
            policyAPI,
            maintenance
        ],
        components: {
            QuoteBreadcrumbs,
            QuoteSummary,
            DetailsForm
        },
        created() {
            this.maintenanceCheck();
        }
    }
</script>

<style scoped>
    #container {
        align-items: flex-start;
    }
</style>