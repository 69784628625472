<template>
    <div>
        <quote-breadcrumbs :step="1"/>
        <div id="container" class="columns">
            <transition appear enter-active-class="animated fadeInUp" leave-active-class="animated fadeOut">
                <product-selection v-if="active" :select-product-fn="select"/>
            </transition>
            <transition appear enter-active-class="animated fadeInUp" leave-active-class="animated fadeOut">
                <quote-summary v-if="active"/>
            </transition>
        </div>
    </div>
</template>

<script>
    import QuoteBreadcrumbs from "../components/quote-journey/QuoteBreadcrumbs";
    import QuoteSummary from "../components/quote-journey/policy-details/QuoteSummary";
    import ProductSelection from "../components/quote-journey/policy-details/ProductSelection";
    import { mapGetters, mapActions } from 'vuex';
    import { policyAPI } from "../mixins/policy-api";
    import { maintenance } from "../mixins/maintenance";

    export default {
        name: "PolicyDetails",
        data() {
           return {
               active: true
           }
        },
        methods: {
            ...mapActions({
                setPolicy: 'setPolicy'
            }),
            select(id, product) {
                this.selectProduct(id, product).then((res) => {
                    if(res.data.quote) {
                        this.setPolicy(res.data);
                        this.$scrollTo('html');
                        this.active = false;
                        setTimeout(() => {
                            this.$router.push({name: 'yourDetails'});
                        }, 1000);
                    }
                });
            }
        },
        computed: {
            ...mapGetters({
                policy: 'policy',
                reqPolicy: 'reqPolicy',
                upsellPolicy: 'upsellPolicy'
            })
        },
        mixins: [policyAPI, maintenance],
        components: {
            QuoteBreadcrumbs,
            QuoteSummary,
            ProductSelection
        },
        created() {
            this.maintenanceCheck();
        }
    }
</script>

<style lang="scss" scoped>
    #container {
        align-items: flex-start;
    }
</style>