<template>
    <div class="column is-9">
        <div class="box b-shdw-3">
            <div class="has-text-centered">
                <h4 class="title is-3">Step 4: Purchase Policy</h4>
                <p>Here's a summary of your travel insurance quote.</p>
                <p>This quote has been saved and emailed to {{ policy.details.email }}</p>

                <!-- <div class="covid-message">
                    *Please Remember - There is no cover on any of our policies if you travel against the Foreign and Commonwealth Office, government, local authority, or medical advice.
                    Terms and Conditions Apply. Please see our <a href="https://covered2go.co.uk/policy-wordings" target="_blank">Policy Wording</a> for full details.
                </div> -->
            </div>
            <hr>

            <h5 class="title is-5">Passengers</h5>

            <div class="columns info passenger-titles-desktop">
                <div class="column"><strong>Name:</strong></div>
                <div class="column"><strong>Date of Birth:</strong></div>
            </div>

            <div class="columns info" v-for="p in policy.passengers" :key="p.id">
                <div class="column is-half passenger-titles-mobile"><strong>Name:</strong></div>
                <div class="column is-half">{{ p.title }} {{ p.forename }} {{ p.surname }}</div>
                <div class="column is-half passenger-titles-mobile"><strong>Date of Birth:</strong></div>
                <div class="column is-half">{{ $moment(p.dob).format('DD/MM/YYYY') }}</div>
            </div>
            <hr>

            <h5 class="title is-5">Summary of Cover</h5>
            <div class="columns info">
                <div class="column"><strong>Policy Type:</strong></div>
                <div class="column">{{ this.policyType }}</div>
            </div>
            <div class="columns info" v-if="!this.policyType.includes('Cruise')">
                <div class="column"><strong>Product:</strong></div>
                <div class="column">{{ $helper.stringCapFirst(policy.quote.product) }}</div>
            </div>
            <div class="columns info">
                <div class="column"><strong>Travel Area:</strong></div>
                <div class="column">{{ this.$c2g.area[policy.quote.travel_zone] }}</div>
            </div>
            <div class="columns info">
                <div class="column"><strong>Start Date:</strong></div>
                <div class="column">{{ $moment(policy.quote.start_date).format('DD/MM/YYYY') }}</div>
            </div>
            <div class="columns info">
                <div class="column"><strong>End Date:</strong></div>
                <div class="column">{{ $moment(policy.quote.end_date).format('DD/MM/YYYY') }}</div>
            </div>
            <hr>

            <div class="address-container">
                <h5 class="title is-5">Address</h5>
                <div class="">{{ policy.details.address1 }}</div>
                <div class="">{{ policy.details.address2 }}</div>
                <div class="">{{ policy.details.address3 }}</div>
                <div class="">{{ policy.details.postcode }}</div>
                <div class="">{{ policy.details.town }}</div>
                <div class="">{{ policy.details.county }}</div>
            </div>
            <div class="columns info">
                <div class="column"><strong>Telephone:</strong></div>
                <div class="column">{{ policy.details.telephone }}</div>
            </div>

            <hr>

            <medical-screening-input/>

            <promo-code-input/>

            <!-- Carona Message -->
            <!-- <div class="alert-important" v-if="content.top_page_content !== '<br>'">
                <b-message type="is-danger">
                    <p v-html="content.top_page_content"></p>

                    <b-field class="confirm-radio">
                        <b-radio-button v-model="conf" :native-value="false" type="is-info">
                            <b-icon icon="close"/> No
                        </b-radio-button>
                        <b-radio-button v-model="conf" :native-value="true" type="is-info">
                            <b-icon icon="check"/> Yes
                        </b-radio-button>
                    </b-field>
                </b-message>
            </div> -->
            <br>

            <div class="notification is-link is-light">
                <p><strong>Please note: We do not accept payment by AMEX</strong></p>
            </div>

            <div id="alert-important" v-if="err">
                <b-message type="is-danger">
                    <p>{{err}}</p>
                </b-message>
            </div>

            <!-- Fidelipay -->
            <form action='https://gateway.fidelipay.co.uk/paymentform/' method="post" 
                v-if="policy.policy.status !== 'policy' && payData && $moment(policy.policy.start_date).isSameOrAfter(new Date(), 'day')">
                
                <input v-for="(v, k) in payData" :key="k.id + 'sad'" type="hidden" :name="k" :value="v"/>

                <button type="submit" class="button is-primary is-medium is-fullwidth hvr-icon-forward b-shdw-1"
                        :class="{'is-loading': loading}">
                    Purchase Policy
                    <i class="fa fa-chevron-circle-right hvr-icon"/>
                </button>
            </form>

            <!-- Test link -->
<!--            <br><br>-->
<!--            <router-link :to="{name:'confirmation'}" tag="button">Test link..</router-link>-->
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import MedicalScreeningInput from "./MedicalScreeningInput";
    import PromoCodeInput from "./PromoCodeInput";
    import { policyAPI } from "../../../mixins/policy-api";

    export default {
        name: "PolicyInformation",
        data() {
            return {
                policyType: '',
                payData: null,
                loading: false,
                conf: false,
                err: null
            }
        },
        props: ['content'],
        methods: {
            // submitFormValidation() {
            //     if(!this.conf) {
            //         this.err = 'You must accept the terms before continuing.';
            //         event.preventDefault()
            //     } else {
            //         this.loading = true;
            //     }
            // }
        },
        computed: {
            ...mapGetters({
                policy: 'policy',
            })
        },
        mixins: [ policyAPI ],
        components: {
            MedicalScreeningInput,
            PromoCodeInput
        },
        created() {
            // Get fidelipay values and signature to send to form
            this.payRequest(this.policy.quote.id).then((res) => {
                this.payData = res.data;
            });

            this.policyType = `${this.policy.endorsements['cruise'] === 'Yes' ? `Cruise -` : ``} ${this.$c2g.policyType[this.policy.quote.type]}`;
        }
    }
</script>

<style lang="scss" scoped>
    .info {
        .column {
            padding-bottom: 2px;
            padding-top: 2px;
        }
    }
    h4 {
        padding-bottom: 7px;
        border-bottom: $c2g_orange 4px solid;
        margin-bottom: 5px !important;
    }
    h5 {
        color: $c2g_orange;
    }
    .covid-message {
        background-color: #f9923167;
        margin: 10px;
        padding: 10px;
        border-radius: 10px;
    }

    .address-container {
        margin-bottom: 30px;
        h5 {
            margin-bottom: 10px;
        }
    }

    @media screen and (max-width: '768px') {
        .passenger-titles-desktop {
            display: none;
        }
    }
    @media screen and (min-width: '768px') {
        .passenger-titles-mobile {
            display: none;
        }
    }
</style>