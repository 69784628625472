<template>
    <div class="columns">
        <div class="column">
            <div class="invalid" v-if="invalid">Invalid date of birth</div>
            <input type="text" class="input" :class="{'is-complete': complete}" :id="'dob' + id" v-model="datesOb" maxlength="10" placeholder="dd/mm/yyyy">
        </div>
    </div>
</template>

<script>
    export default {
        name: "DateOfBirthSelector",
        data() {
            return {
                invalid: false,
                complete: false
            }
        },
        props: {
            value: { required: false },
            id: { required: true, type: Number }
        },
        computed: {
            datesOb: {
                get() {
                    return this.value;
                },
                set(v) {
                    this.$emit('input', v);
                }
            }
        },
        methods: {
            validate() {
                // Validation
                if(this.datesOb.length === 10) {
                    if(!this.$moment(this.datesOb, 'DD/MM/YYYY', true).isValid()) {
                        this.invalid = true;
                    } else {
                        this.complete = true;
                    }
                } else {
                    this.invalid = false;
                    this.complete = false;
                }
            }
        },
        watch: {
            datesOb(nv, ov) {
                // Don't allow characters
                if (nv.match(/[a-z]/i)) {
                    this.datesOb = ov;
                }
                // When adding to the DOB selector add slashes
                if((nv.length === 2 || nv.length === 5) && nv.length > ov.length) {
                    this.datesOb += '/';
                // Remove slashes and last character
                } else if(nv.length < ov.length && ov[ov.length - 1] === '/') {
                    this.datesOb = this.datesOb.substring(0, this.datesOb.length - 1);
                }
                this.validate();
            }
        },
        created() {
            this.validate();
        }
    }
</script>

<style lang="scss" scoped>
    .invalid {
        color: red;
        margin-bottom: 5px;
    }
    .is-complete {
        background-color: $c2g_orange;
        border-color: $c2g_orange;
        color: white;
    }
</style>