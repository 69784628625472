<template>
    <section>
        <div class="columns">
            <div class="input-label title is-6 column">
                <div>Sports and Activities Search</div>
              <span style="font-weight: normal">Search for the sports or activities you need to cover </span>
                <b-button style="float: right; color: #494949;" :type="'is-ghost'" size="is-small" @click="showInfo = !showInfo" class="hvr-icon-bob is-hidden-desktop"><i class="fas fa-question-circle hvr-icon"/></b-button>
            </div>

            <div class="column is-two-fifths">
                <b-input v-model="searchString" type="search" placeholder="Type in your sport / activity" icon="magnify"/>
            </div>

            <div class="column is-hidden-touch">
                <b-button style="float: right; color: #494949;" :type="'is-ghost'" size="is-small" @click="showInfo = !showInfo" class="hvr-icon-bob"><i class="fas fa-question-circle hvr-icon"/></b-button>
            </div>
        </div>

        <div class="levels" v-if="searchedActivities.length > 0 || addedActivities.length > 0">
            <div class="level">
                <!-- Searched activities container -->
                <div class="box" v-if="searchedActivities.length > 0">
                    <h6 class="title is-6">These activities match your query:</h6>
                    <div class="columns is-multiline">
                        <div v-for="a in searchedActivities" :key="a.id" @click="addActivity(a)" size="is-medium" class="hvr-icon-grow">
                            <div class="activity" v-html="`<i class='fa fa-plus-square hvr-icon'></i> ` + $helper.boldenStringMatch(a.activity, searchString)"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="level">
                <!-- Added activies container -->
                <div class="box" v-if="addedActivities.length > 0">
                    <h6 class="title is-6">You have <u>Level {{ quoteData.activityPack }}</u> based on your added activities:</h6>
                    <div class="columns" style="overflow-x: auto">
                        <table>
                            <thead>
                            <tr>
                                <th width="60%">Activity</th>
                                <th width="20%">Conditions</th>
                                <th width="20%">Exclusions</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="a in addedActivities" :key="a.id" @click="removeActivity(a.id)" size="is-medium" content="hvr-icon-grow">
                                <td v-html="`<i class='fa fa-minus-square hvr-icon'></i> ` + $helper.boldenStringMatch(a.activity, searchString)"></td>
                                <td>{{ a.conditions == "" ? "No conditions" : a.conditions }}</td>
                                <td>{{ a.exclusions == "" ? "No exclusions" : a.exclusions }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <b-button :type="'is-light'" size="is-small" @click="showCons" class="hvr-icon-bob">{{ showConditions ? "Hide Conditions" : "Show Conditions" }}</b-button>
                    <div style="padding: 0.4rem;" v-if="showConditions">
                        <br />
                        <strong>Special Conditions</strong>
                        <ol style="list-style-type: lower-alpha">
                            <li>You must be with a professional, qualified and licensed guide, instructor or operator.</li>
                            <li>
                                You must have the appropriate certification or licence to do this sport or activity at Home.
                                If operating a motor vehicle the driver must have the appropriate valid UK licence for the machine.
                            </li>
                            <li>
                                within 60 miles of a safe haven (a protected body of water used by marine craft for refuge from
                                storms or heavy seas).
                            </li>
                            <li>
                                Conventional skiing / snowboarding only. It is not a condition of cover that you ski or board with a guide, however, you
                                must follow the International Ski Federation code or the resort regulations and we recommend that you do not venture
                                into back country areas without taking local advice and appropriate rescue equipment;
                            </li>
                            <li>
                                You must hold a British Sub Aqua Club certificate or Professional Association of Diving
                                Instructors certificate or equivalent and follow the relevant Club or Association rules and
                                guidelines at all times, or You must only dive under the constant supervision of a properly
                                licensed diving school and follow their rules and instructions at all times.
                            </li>
                        </ol>

                        <br />
                        <strong>Special Exclusions</strong>
                        <ol style="list-style-type: lower-latin">
                            <li>
                                No cover for any competition, free-style skiing / snowboarding, ski/ snowboard jumping, ski-flying, ski / snowboard
                                acrobatics, ski / snowboard stunting, or ski racing or national squad training, the use of skeletons.
                            </li>
                            <li>
                                No cover for any unaccompanied dive, any dive in overhead environments, or any dive for gain or reward.
                            </li>
                        </ol>

                        <br />
                        <strong>Condition</strong>
                        <ol>
                            <li>
                                You must act in a reasonable way and use all recommended safety equipment and protective clothing
                                that is necessary and undergo any required training.
                            </li>
                        </ol>

                        <br />
                        <strong>Not covered</strong>

                        <p>Cover does not apply if you are;</p>

                        <ol>
                            <li>taking part in activities at a professional level;</li>
                            <li>competing at international events as a national representative;</li>
                            <li>participating in hazardous activities or extreme pursuits other than as listed;</li>
                            <li>racing or participating in speed or time trials;</li>
                            <li>motorbike touring or where a motorbike is the main mode of transport;</li>
                            <li>base Jumping, cliff diving, martial arts competitions, mountaineering, potholing, caving (other than sightseeing/tourist attraction) motor sports, stunt flying / aerobatics;</li>
                            <li>taking part in expeditions to the Arctic or Antarctic;</li>
                            <li>taking part in dangerous expeditions or the crewing of a vessel more than 60 miles from a safe haven;</li>
                            <li>anything listed in the General Exclusions.</li>
                        </ol>

                    </div>
                </div>
            </div>
        </div>

        <transition appear enter-active-class="animated fadeIn faster" leave-active-class="animated fadeOut faster">
            <div v-if="showInfo" class="box">
                <p>
                    Your travel insurance covers you for many sports and activities as standard, which are listed as ‘Level 1’.
                </p><br />
                <p>
                    Any other sports and activities will need an extra premium to be paid for cover to apply and it will
                    be shown on your schedule. If you are participating in an activity that is not listed, you must
                    contact us to ensure you have full cover, cover will not be in place until we have confirmed
                    acceptance and any additional premium paid.
                </p><br />
                <p>
                    If you select a sport or activity in Level 4 you will be covered for all sports and activities
                    listed as Level 1, 2, 3 and 4.
                </p><br />
                <p>
                    If you choose a sport or activity in Level 3 you will be covered for all sports and activities
                    listed as Level 1, 2 and 3.
                </p><br />
                <p>
                    If you choose a sport and activity in Level 2 you are covered for all sports and activities listed
                    as Level 1 and 2.
                </p><br />
                <p>
                    Please see the Policy Wording for full policy terms.
                </p>
            </div>
        </transition>

    </section>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "QuoteActivitySelector",
        data() {
            return {
                showInfo: false,
                searchString: '',
                searchedActivities: [],
                addedActivities: [],
                showConditions: false,
            }
        },
        props: {
            value: { required: false },
            quoteData: { type: Object, required: true }
        },
        methods: {
            // Add an activity to list
            addActivity(act) {
                this.addedActivities.push(act);
            },
            // Remove an activity from list
            removeActivity(aid) {
                this.addedActivities.forEach((a, i) => {
                    if(a.id === aid) {
                        this.addedActivities.splice(i, 1);
                    }
                });
            },
            // Query API to figure out the correct AP
            assessAP() {
                // Get activities list by ID
                let addedActivitiesNumbers = [];
                this.addedActivities.forEach((a) => {
                    if (typeof a !== "undefined") {
                        addedActivitiesNumbers.push(a.id);
                    }
                });
                this.quoteData.activities = addedActivitiesNumbers.join();
                // Query API for the correct AP based on activities
                this.$c2g.assessActivityPack(this.quoteData.activities, this.quoteData.endorsements.winter_sports).then((response) => {
                    this.quoteData.activityPack = response.data;
                });
            },
            showCons() {
                this.showConditions = !this.showConditions;
            }
        },
        watch: {
            // Search the activity API for an activity matching string
            searchString() {
                if(this.searchString.length <= 1) {
                    this.searchedActivities = [];
                } else {
                    // Query API for activity
                    this.$c2g.getActivities(this.searchString).then((response) => {
                        let i = 0;
                        response.data.forEach(a => {
                            this.$set(this.searchedActivities, i, a);
                            i++;
                        });
                    });
                }
            },
            // Empty the search
            addedActivities() {
                this.searchString = '';
                this.assessAP();
            },
            'quoteData.endorsements'() {
                this.assessAP();
            }
        },
        computed: {
            ...mapGetters({
                policy: 'policy'
            })
        }
    }
</script>

<style lang="scss" scoped>
    .activity {
        padding: 5px;
        margin: 5px;
        background-color: #e2e2e2;
        border: 1px solid black;
        font-size: 14px;
        border-radius: 4px;
        cursor: pointer;
    }
    .activity-added {
        background-color: rgba(249, 145, 49, 0.4);
    }
    .box {
        padding-top: 10px;
        margin-top: 10px;
    }
    .input-label {
        margin-top: 10px;
        margin-bottom: 0;
        padding-bottom: 0;
    }

    table {
        width: 100%;
    }
</style>