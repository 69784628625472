<template>
    <section>
        <div class="columns">
            <div class="input-label title is-6 column">
                <div>What Is The Total Cost Of Your Trip?</div>
                <b-button style="float: right; color: #494949;" :type="'is-ghost'" size="is-small" @click="showInfo = !showInfo" class="hvr-icon-bob is-hidden-desktop"><i class="fas fa-question-circle hvr-icon"/></b-button>
            </div>
            <div class="column is-two-fifths">
                <span :v-if="quoteData.errors.tripCost !== ''" class="input-error">{{ quoteData.errors.tripCost }}</span>
                <b-input icon="currency-gbp" :expanded="true" type="is-info" v-model="quoteData.tripCost"/>
            </div>
            <div class="column is-hidden-touch">
                <b-button style="float: right; color: #494949;" :type="'is-ghost'" size="is-small" @click="showInfo = !showInfo" class="hvr-icon-bob"><i class="fas fa-question-circle hvr-icon"/></b-button>
            </div>
        </div>
        <transition appear enter-active-class="animated fadeIn faster" leave-active-class="animated fadeOut faster">
            <div v-if="showInfo" class="box">
                <p>Single Trip: The cost of your trip is needed to accurately calculate your insurance premium. This is the total cost for all parties that require insurance on this policy.</p>
                <br>
                <p>Multi trip: We understand you may not have booked all your travel plans for the year ahead, however to ensure you have the correct cover in place just in case you need to cancel any of your trips, we would ask that you enter the total cost of your most expensive holiday. If you book a more expensive trip after purchasing this policy, please just give us a call 0333 400 7745 and we can amend the policy for you.</p>
            </div>
        </transition>
        <hr>
    </section>

</template>

<script>
    export default {
        name: "QuoteTotalTripCost",
        data() {
            return {
                showInfo: false
            }
        },
        props: ['quoteData'],
        watch: {
            'quoteData.tripCost'(val) {
                this.quoteData.tripCost = val.replace(/,/g,'');
            }
        }
    }
</script>

<style scoped>
    .input-label {
        margin-top: 10px;
        margin-bottom: 0;
    }
</style>